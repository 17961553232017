// SearchPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/SearchPage.css';
import logo1 from './image/logo.png';  // Import your logos
import logo2 from './image/searchfurLogo.png';

const logos = [logo1, logo2];

const getRandomLogo = () => {
  const randomIndex = Math.floor(Math.random() * logos.length);
  return logos[randomIndex];
};

const SearchPage = () => {
  const [query, setQuery] = useState('');
  const [darkMode, setDarkMode] = useState(false);
  const [logo, setLogo] = useState(getRandomLogo());
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (query.trim()) {
      navigate(`/search?q=${query}`);
    }
  };

  return (
    <div className={`search-page ${darkMode ? 'dark-mode' : ''}`}>
      <header className="header">
      </header>
      <div className="logo-container">
        <img className="logo" alt="Logo" src={logo} />
      </div>
      <div className="search-bar-container">
        <form onSubmit={handleSearch} className="search-form">
          <input
            className="search-bar"
            type="text"
            title="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button type="submit" className="voice-search-button">
            <img className="voice-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/55/Magnifying_glass_icon.svg" title="Search by Voice" alt="voice search" />
          </button>
        </form>
      </div>
      <div className="search-buttons">
        <button className="search-button" type="button" onClick={handleSearch}>SearchFur Beta</button>
        <button className="search-button" type="button">I'm Feeling Furry</button>
      </div>
    </div>
  );
};

export default SearchPage;
