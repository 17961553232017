import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields
} from "./config/config-helper";
import CustomSearchBox from "./CustomSearchBox";
import './styles/App.css';  // Import your custom CSS
import logo from './image/logo.png';  // Import your logo
import ResultWithImage from "./ResultWithImage"; // Import the new component
import ArtworkApp from "./App-Artworkmode"; // Import the artwork mode app

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase
});
const config = {
  searchQuery: {
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig()
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true
};

export default function App() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const initialQuery = params.get('query') || '';
  const [isArtworkMode, setIsArtworkMode] = useState(false); // State to manage mode

  return (
    <SearchProvider config={{ ...config, initialQuery }}>
      <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
        {({ wasSearched }) => (
          <div className="App">
            <ErrorBoundary>
              <div className="header">
                <img src={logo} alt="Logo" className="logo" />
                <CustomSearchBox />
                <button 
                  onClick={() => setIsArtworkMode(!isArtworkMode)} 
                  className="switch-button"
                >
                  {isArtworkMode ? "Switch to Default Mode" : "Switch to Artwork Mode"}
                </button>
              </div>
              {isArtworkMode ? (
                <ArtworkApp /> // Render the artwork mode app
              ) : (
                <Layout
                  sideContent={
                    wasSearched && (
                      <div className="sideContent">
                        <Sorting
                          label="Sort by"
                          sortOptions={buildSortOptionsFromConfig()}
                        />
                        {getFacetFields().map(field => (
                          <Facet key={field} field={field} label={field} />
                        ))}
                      </div>
                    )
                  }
                  bodyContent={
                    <div className="resultsWrapper">
                      <div className="resultsContainer">
                        <Results
                          titleField={getConfig().titleField}
                          urlField={getConfig().urlField}
                          thumbnailField={getConfig().thumbnailField}
                          shouldTrackClickThrough
                        />
                      </div>
                    </div>
                  }
                  bodyHeader={
                    wasSearched && (
                      <>
                        <PagingInfo />
                        <ResultsPerPage />
                      </>
                    )
                  }
                  bodyFooter={
                    <div className="pagingContainer">
                      <Paging />
                    </div>
                  }
                />
              )}
            </ErrorBoundary>
          </div>
        )}
      </WithSearch>
    </SearchProvider>
  );
}
