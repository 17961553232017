import React, { useState } from "react";
import axios from 'axios';
import Modal from 'react-modal';
import { ClipLoader } from 'react-spinners';
import "./styles/ResultWithImage.css"; // Import CSS for styling

const CORS_PROXY = "https://cors-anywhere-nw0j.onrender.com/";

const ResultWithImage = ({ result }) => {
  const [images, setImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fetchFailed, setFetchFailed] = useState(false);

  const fetchImages = async (url) => {
    setIsLoading(true);
    setFetchFailed(false);
    try {
      const response = await axios.get(CORS_PROXY + url);
      const parser = new DOMParser();
      const doc = parser.parseFromString(response.data, "text/html");

      // Try to find meta tags for the main image
      const metaTags = Array.from(doc.querySelectorAll('meta[property="og:image"], meta[name="twitter:image"]'));
      const mainImages = metaTags.map(tag => tag.getAttribute("content"));

      // Fallback to large images on the page if no meta tags are found
      if (mainImages.length === 0) {
        const imgElements = Array.from(doc.querySelectorAll("img")).filter(img => {
          const width = img.getAttribute("width");
          const height = img.getAttribute("height");
          const area = width && height ? parseInt(width) * parseInt(height) : 0;
          return area > 50000; // Filter for images larger than 50,000 pixels
        });
        const fallbackImages = imgElements.map(img => {
          let imgSrc = img.getAttribute("src");
          if (imgSrc && !imgSrc.startsWith("http")) {
            const urlObj = new URL(url);
            imgSrc = `${urlObj.origin}${imgSrc}`;
          }
          return imgSrc;
        }).filter(src => src);
        setImages(fallbackImages);
      } else {
        setImages(mainImages);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching images:", error);
      setIsLoading(false);
      setFetchFailed(true);
    }
  };

  const openModal = (src) => {
    setCurrentImage(src);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImage("");
  };

  return (
    <div className="result-container">
      <div className="result-header">
        <a href={result.url.raw}>{result.title.raw}</a>
        <button 
          onClick={() => fetchImages(result.url.raw)} 
          className={`fetch-button ${fetchFailed ? "retry-button" : ""}`}
          disabled={isLoading}
        >
          {isLoading ? <ClipLoader size={15} color={"#fff"} /> : fetchFailed ? "Retry" : "Fetch Images"}
        </button>
      </div>
      <div className="thumbnail-container">
        {images.length > 0 && images.map((src, index) => (
          <div key={index} className="thumbnail" onClick={() => openModal(src)}>
            <img src={src} alt={`Fetched ${index}`} className="thumbnail-image" />
          </div>
        ))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="image-modal"
        overlayClassName="image-modal-overlay"
      >
        <button onClick={closeModal} className="close-modal-button">x</button>
        <img src={currentImage} alt="Full Size" className="full-size-image" />
      </Modal>
    </div>
  );
};

export default ResultWithImage;
