// CustomSearchBox.js
import React from "react";
import { SearchBox } from "@elastic/react-search-ui";

const CustomSearchBox = () => {
  const handleSearchClick = (submitSearch) => {
    submitSearch();
  };

  return (
    <div className="search-container">
      <div className="bar">
        <SearchBox
          inputView={({ getAutocomplete, getInputProps, getButtonProps, submitSearch }) => (
            <div className="input-wrapper">
              <input
                {...getInputProps({
                  className: "searchbar",
                  placeholder: "Search"
                })}
                title="Search"
              />
              {getAutocomplete()}
              <button
                {...getButtonProps({
                  onClick: () => handleSearchClick(submitSearch)
                })}
                className="search-icon-button"
              >
                <img className="search-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/55/Magnifying_glass_icon.svg" title="Search" alt="search icon" />
              </button>
            </div>
          )}
          autocompleteSuggestions={true}
        />
      </div>
    </div>
  );
};

export default CustomSearchBox;
